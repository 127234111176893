import FetchUtils from 'src/utils/fetch_utils';

class DashboardUtils {
  static hideLoader(container) {
    const loader = container.querySelector('.js-sm-loader');
    if (loader) {
      container.querySelector('.js-sm-loader').remove();
    }
  }


  static getUrl(urlData, container) {
    let url = '';
    if (urlData.url) {
      url = urlData.url;
    } else {
      const filter = container.previousElementSibling;
      if (container.getAttribute('remote-href')) {
        url = container.getAttribute('remote-href');
      }else if (filter.querySelector('a.current').getAttribute('remote-href')) {
        url = filter.querySelector('a.current').getAttribute('remote-href');
      } else{
        url = filter.querySelector('a.current').getAttribute('href');
      }
      url = new URL(url, document.baseURI);
      let params = new URLSearchParams(url.search);
      params.append('page', urlData.page);
      url.search = params.toString();
    }
    return url;
  }

  static fetchListings(urlData, container, onComplete) {
    const url = DashboardUtils.getUrl(urlData, container);
    
    fetch(url, {headers: {'Content-Type': 'application/json'}}).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(data => {
        if (!data.page) {
          $('#listings_box').html(data.list);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listings_box'}}));
        } else if (data.append_listings) {
          document.querySelector('#listings .js-infinite-list-inner').insertAdjacentHTML('beforeend', data.list);
        } else {
          DashboardUtils.hideLoader(container);
          document.querySelector('.js-listings-infinite-list').dispatchEvent(new CustomEvent('infinite-loader:disconnect'));
        }

        if (onComplete) {
          onComplete();
        }

        $(document)[0].dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listings_box', keepOpened: true}}));
      }).catch((err) => {
        DashboardUtils.hideLoader(document.querySelector('#listings_box'));
        FetchUtils.handleResponseError(err);
      });
  }

  static fetchShowings(urlData, container, onComplete) {
    const url = DashboardUtils.getUrl(urlData, container);
    const showingWrapperSelector = '#showings_box';
    
    fetch(url, {headers: {'Content-Type': 'application/json'}}).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(data => {
        if (!data.page) {
          $(showingWrapperSelector).html(data.list);
          $('#buttons ul').remove();
          $('#buttons').prepend(data.buttons);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: showingWrapperSelector}}));
        } else if (data.append_showings) {
          document.querySelector(`#${data.showings_container_id} .js-infinite-list-inner`).insertAdjacentHTML('beforeend', data.list);
        } else {
          DashboardUtils.hideLoader(document.querySelector(showingWrapperSelector));
          document.querySelector(`#${data.showings_container_id}.js-showings-infinite-list`).dispatchEvent(new CustomEvent('infinite-loader:disconnect'));
        }
        if (typeof window.toggleLeadInitial !== 'undefined') {
          window.toggleLeadInitial();
        }

        if (onComplete) {
          onComplete();
        }
        $(document)[0].dispatchEvent(new CustomEvent('html-updated', {detail: {container: showingWrapperSelector, keepOpened: true}}));
      }).catch((err) => {
        DashboardUtils.hideLoader(document.querySelector(showingWrapperSelector));
        FetchUtils.handleResponseError(err);
      });
  }

  static toggleEmailSchedule() {
    var bListingIdPresent = document.querySelector('#showing_listing_id').value !== '',
      bShowtimeBlank = document.querySelector('#showing_showtime')?.value === '';
  
    const show = bListingIdPresent && bShowtimeBlank && DashboardUtils.isValidEmail();
    if (show) {
      LayoutUtils.show(document.querySelector('#email_link'));
    } else {
      LayoutUtils.hide(document.querySelector('#email_link'));
    }
     
    return false;
  }

  static isValidEmail(val) {
    if (!val) { val = $('#showing_email').val(); }
    return DashboardUtils.emailRegExp.test(val);
  }

  static emailRegExp = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  static initSampleLink(link) {
    link.innerHTML = 'Sample email sent. Send another.';
    let toOwnerInput = document.createElement('input');
    toOwnerInput.setAttribute('type', 'hidden');
    toOwnerInput.setAttribute('name','to_owner');
    const form = document.querySelector('form#email_form');
    form.append(toOwnerInput);
    Rails.fire(form, 'submit');
    const formInputs = form.querySelectorAll('input');
    formInputs[formInputs.length - 1].remove();
    return false;
  }

  static updateShowingHtml(showingId, html) {
    const showing = document.querySelector(`#showing_${showingId}`);
    if (showing) {
      showing.innerHTML = html;
      LayoutUtils.highlight(showing);
      if (typeof toggleLeadInitial !== 'undefined') {
        toggleLeadInitial();
      }
    }

    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showings_box'}}));
  }

  static updateShowing(showing, errors, showingHtml, formHtml, oldMobileSite) {
    if (errors) {
      document.querySelector('#edit-showing').innerHTML = formHtml;
      document.dispatchEvent(new CustomEvent('showings-popup:reloaded'));
      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#edit-showing', keepOpened: true}}));
    } else {
      if (oldMobileSite) {
        window.location = `/showing_details/${showing.id}`;
      } else {
        DashboardUtils.updateShowingHtml(showing.id, showingHtml);
      }
    }
  }
}

export default DashboardUtils;