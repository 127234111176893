import ISpin from 'ispin';
import 'ispin/dist/ispin.css';

class SMNumberInput {
  init(container = 'body') {
    document.querySelectorAll(`${container} .js-sm-number-input`).forEach(el => {
      let options = {
        parse: Number
      };
      if (el.step) {
        options.step = +el.step;
      }
      if (el.min) {
        options.min = el.min;
      }
      if (el.max) {
        options.max = el.max;
      }
      new ISpin(el, options);
      el.closest('.ispin-wrapper').querySelectorAll('.ispin-button').forEach(button => {
        button.addEventListener('click', () => {
          el.dispatchEvent(new Event('change'));
        });
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const smInput = new SMNumberInput();
  smInput.init();
  document.addEventListener('html-updated', (e) => {
    smInput.init(e.detail ? e.detail.container : undefined);
  });
  document.addEventListener('sm-modal:opened', () => {
    smInput.init('.js-sm-modal');
  });
});
