class InfoBox {
  getPopupHtml(links, submitTicketUrl) {
    let linksHtml = '';
    links.forEach(l => {
      linksHtml += `<li><a href=${l.url} target='_blank'>${l.title !== '' && l.title ? l.title : l.url}</a></li>`;
    });
    return `<div class="knowledgebase-links-popover"><h3>Knowledge Base Articles</h3>
      <ul class="knowledgebase-links">${linksHtml}</ul>
      <p><span class='midbold'>Have a question?</span> <a href="${submitTicketUrl}" target='_blank'>Submit a Ticket</a></p>
      <div><a class='btn btn-default js-modal-close-trigger'>Cancel</a></div></div>`;
  }

  bindEvents(wrap) {
    const links = JSON.parse(wrap.dataset.knowledgeBaseLinks);
    wrap.addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: this.getPopupHtml(links, wrap.dataset.submitTicketUrl)
      }}));
    });
  }

  init(wrap) {
    if (!wrap.dataset.inited) {
      wrap.setAttribute('data-inited', true);
      this.bindEvents(wrap);
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initLinks() {
    document.querySelectorAll('.js-info-link').forEach(el => {
      new InfoBox().init(el);
    });
  }
  initLinks();

  document.addEventListener('html-updated', () => {
    initLinks();
  });
});
