import SignaturePad from 'signature_pad';
import './styles.scss';

class SignatureField {
  constructor(canvas) {
    this.canvas = canvas;
    this.form = this.canvas.closest('form');
  }

  resizeCanvas() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);
  }

  setSignFieldBeforeSubmit() {
    const signField = this.form.querySelector('.js-sign-value');
    if (!this.signaturePad.isEmpty()) {
      signField.value = this.signaturePad.toDataURL();
    } else {
      signField.value = '';
    }
  }

  clearCanvas(e) {
    e.preventDefault();
    this.signaturePad.clear();
    return false;
  }

  bindEvents() {
    //iphone/ipad triggering unexpected resize events. So we check was window actually resized.
    //https://stackoverflow.com/questions/8898412/iphone-ipad-triggering-unexpected-resize-events/24212316#24212316
    let windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== windowWidth) {
        windowWidth = window.innerWidth;
        this.resizeCanvas();
      }
    });
    this.form.querySelector('.js-clear-sign').addEventListener('click', e => this.clearCanvas(e));
    this.form.querySelector('[type="submit"]').addEventListener('click', () => this.setSignFieldBeforeSubmit());
  }

  initField() {
    this.resizeCanvas();
    this.signaturePad = new SignaturePad(this.canvas);
  }

  init() {
    this.bindEvents();
    this.initField();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initSignatire(wrapper = 'body') {
    const canvas = document.querySelector(`${wrapper} canvas.js-sign`);
    if (canvas) {
      new SignatureField(canvas).init();
    }
  }

  initSignatire();

  document.addEventListener('html-updated', (e) => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initSignatire(wrapper);
  });

  document.addEventListener('sm-modal:opened', () => {
    initSignatire('.js-sm-modal');
  });
});
