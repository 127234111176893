import 'quill/dist/quill.bubble.css';
import './styles.scss';

class TemplateBubbleEditor {
  #editor;

  init(container) {
    const content = container.dataset.value;

    this.#editor = new window.Quill(container, {
      theme: 'bubble', 
      bounds: container
    });

    if (content) {
      this.setContent(content);
    }

    container.dispatchEvent(new CustomEvent('sm-rich-editor:initialized'));
    return this;
  }

  setContent(html) {
    html = this.decorateContent(html);
    this.#editor.setContents(this.#editor.clipboard.convert({html: html}));
  }
}

window.TemplateBubbleEditor = TemplateBubbleEditor;