import Cookies from 'js-cookie';

class Sidebar {
  #sidebarClosedCookie = 'sm-sidebar-closed';

  bindPopupSidebarEvents() {
    const popupSidebar = document.querySelector('.js-popup-sidebar');
    const popupSidebarIcon = document.querySelector('.js-popup-sidebar-icon');
    let timer = null;

    popupSidebarIcon.addEventListener('touchstart', () => {
      if (popupSidebar.classList.contains('hidden')) {
        this.openPopupSidebar();
      } else {
        this.closePopupSidebar();
      }
    });

    popupSidebarIcon.addEventListener('mouseenter', () => {
      clearTimeout(timer);
      this.openPopupSidebar();
    });

    popupSidebarIcon.addEventListener('mouseleave', () => {
      timer = setTimeout(() => {
        if (![...document.querySelectorAll(':hover')].includes(popupSidebar)) {
          this.closePopupSidebar();
        }
      }, 600);
    });

    popupSidebar.addEventListener('mouseleave', () => {
      this.closePopupSidebar();
    });

    document.querySelectorAll('.js-popup-sidebar a').forEach(l => {
      l.addEventListener('click', () => {
        this.closePopupSidebar();
      });
    });
  }

  setSidebarState(show) {
    const toggleSidebar = document.querySelector('.js-toggle-sidebar');
    const navbarInner = document.querySelector('.js-main-sidebar > ul');

    if (!show) {
      navbarInner.classList.add('hidden');
    }
    document.querySelector('body').toggleAttribute('data-closed-side-navbar');
    toggleSidebar.querySelectorAll('i').forEach(el => {
      el.classList.toggle('hidden');
    });
    if (show) {
      setTimeout(() => navbarInner.classList.remove('hidden'), 300);
    }
    Cookies.set(this.#sidebarClosedCookie, !show);
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('sidebar:state-changed'));
    }, 500);
  }

  bindToggleSidebarEvents() {
    const navbarInner = document.querySelector('.js-main-sidebar > ul');
    const toggleSidebar = document.querySelector('.js-toggle-sidebar');
    toggleSidebar.addEventListener('click', () => {
      this.setSidebarState(navbarInner.classList.contains('hidden'));
    });
  }

  closePopupSidebar() {
    document.querySelector('.js-popup-sidebar').classList.add('hidden');
    document.querySelector('.js-popup-sidebar-overlay').classList.add('hidden');
  }

  openPopupSidebar() {
    const popupIcon = document.querySelector('.js-popup-sidebar-icon');
    const sidebar = document.querySelector('.js-popup-sidebar');
    sidebar.classList.remove('hidden');
    const overlay = document.querySelector('.js-popup-sidebar-overlay');
    overlay.style.height = `${sidebar.getBoundingClientRect().bottom}px`;
    overlay.classList.remove('hidden');
    const popup = document.querySelector('.js-popup-sidebar');
    popup.style.top = `${parseInt(popupIcon.style.top) - 50}px`;
  }

  bindEvents() {
    this.bindToggleSidebarEvents();
    this.bindPopupSidebarEvents();

    document.addEventListener('sidebar:popup-close', () => {
      this.closePopupSidebar();
    });

    window.addEventListener('resize', () => {
      this.setMobilePopupIconPosition();
    });
  }

  showDividers() {
    // Show hr tag for sidebar only if there is some content after it
    document.querySelectorAll('.js-main-sidebar .nav hr + li, .js-popup-sidebar .nav hr + li').forEach(el => {
      if (el.innerHTML && el.innerHTML.trim() !== '') {
        el.previousElementSibling.classList.remove('hidden');
      }
    });
  }

  setupPopupMenu() {
    const menu = document.querySelector('.js-popup-sidebar > ul');
    if (menu) {
      menu.classList.remove('hidden');
    }
  }

  setMobilePopupIconPosition() {
    setTimeout(()=> {
      const popupIcon = document.querySelector('.js-popup-sidebar-icon');
      if (window.innerWidth <= 620) {
        let container = document.querySelector('#content') || document.querySelector('.js-sidebar-popup-top');
        if (!container) {
          // Reports pages have another selector for the main container
          container = document.querySelector('#content-wrap');
        }
        if (container) {
          popupIcon.style.top = container.offsetTop + 'px';
        }
      } else {
        popupIcon.style.top = '90px';
      }
    }, 300);
  }

  init() {
    this.setupPopupMenu();
    this.bindEvents();
    this.showDividers();
    this.setMobilePopupIconPosition();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.js-main-sidebar')) {
    new Sidebar().init();
  }
});
