import tippy, {hideAll} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import './styles.scss';

const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn({hide}) {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        hide();
      }
    }

    return {
      onShow() {
        document.addEventListener('keydown', onKeyDown);
      },
      onHide() {
        document.removeEventListener('keydown', onKeyDown);
      },
    };
  },
};

class SMDropdown {
  // @param {(String|Element|Element[]|NodeList)} element 
  // @param {Object} options
  // More info https://atomiks.github.io/tippyjs/
  static init(element, options) {
    const defaults = {
      trigger: 'click',
      placement: 'bottom',
      arrow: false,
      allowHTML: true,
      theme: 'dropdown',
      interactive: true,
      plugins: [hideOnEsc],
      appendTo: () => document.body
    };
    if (!element._tippy) {
      return tippy(element, {...defaults, ...options} );
    }
  }

  static closeAll() {
    hideAll();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.js-sm-dropdown').forEach(d => {
    SMDropdown.init(d, {
      content: d.parentNode.querySelector('.js-dropdown-content').innerHTML
    });
  });

  document.addEventListener('html-updated', (e) => {
    const wrap = e.detail && e.detail.container ? e.detail.container : 'body';
    document.querySelectorAll(`${wrap} .js-sm-dropdown`).forEach(d => {
      SMDropdown.init(d, {
        content: d.parentNode.querySelector('.js-dropdown-content').innerHTML
      });
    });
  });
});

export default SMDropdown;