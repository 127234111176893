// Tooltips which are shown only if text is truncated

class TooltipForTruncatedText {
  checkTooltip(el) {
    if (el.offsetWidth < el.scrollWidth){
      el.setAttribute('title', el.innerText);
    } else {
      el.removeAttribute('title');
    }
  }

  bindEvents(el) {
    window.addEventListener('resize', () => this.checkTooltip(el));
  }

  init(el) {
    this.bindEvents(el);
    this.checkTooltip(el);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initTooltips(container = 'body') {
    document.querySelectorAll(`${container} .js-tooltip-for-truncated`).forEach(el => {
      new TooltipForTruncatedText().init(el);
    });
  }

  initTooltips();

  document.addEventListener('html-updated', (e) => {
    initTooltips(e.detail ? e.detail.container : null);
  });
});