class PlainShowtimePicker {
  #container;

  init(container) {
    this.#container = container;
    container.querySelectorAll('.showtime').forEach((btn) => {
      btn.addEventListener('click', function(e) {
        const button = e.target;
        const showtime = button.dataset.value;
        const showtimeDisplay = button.dataset.display;
        const surgeShowtime = button.dataset.surgeShowtime;
  
        container.querySelectorAll('.active').forEach((el) => {
          el.classList.remove('active');
        });
        container.querySelectorAll('.showtime').forEach((el) => {
          el.classList.add('showtime-inactive');
        });
        button.classList.remove('showtime-inactive');
        button.classList.add('active');
  
        container.dispatchEvent(new CustomEvent('showtime:selected', {detail: {showtime: showtime, showtimeDisplay: showtimeDisplay, surgeShowtime: surgeShowtime}}));
      });
    });

    this.bindEvents();
  }

  bindEvents() {
    this.#container.addEventListener('showtime-picker:setShowtime', (e) => {
      this.setShowtime(e.detail.showtime);
    });
    this.#container.addEventListener('showtime-picker:clear', () => {
      this.clear();
    });
  }

  setShowtime(value) { 
    const isSuitableTime = TimepickerBuilder.isSuitableTime(this.#container, value);
    const active = this.#container.querySelector('.showtime[data-display="' + value + '"]');
    if (active) {
      active.classList.add('active');
      const elems = this.#container.querySelectorAll('.showtime:not([data-display="' + value + '"])');
      elems.forEach((elem) => {
        elem.classList.remove('active');
        elem.classList.add('showtime-inactive');
      });
    }
    if (!isSuitableTime){
      this.clear();
    } else {
      const showtime = active.dataset.value;
      const showtimeDisplay = active.dataset.display;
      const surgeShowtime = active.dataset.surgeShowtime;
      this.#container.dispatchEvent(new CustomEvent('showtime:selected', {detail: {showtime, showtimeDisplay, surgeShowtime}}));
    }
  }

  clear() {
    this.#container.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });
    this.#container.querySelectorAll('.showtime-inactive').forEach((el) => {
      el.classList.remove('showtime-inactive');
    });
  }
}

export default PlainShowtimePicker;
