class CopyIcon {
  bindEvents(el) {
    el.addEventListener('click', () => {
      const field = el.previousElementSibling;
      field.select();
      field.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(field.value);
    });
  }

  init(el) {
    this.bindEvents(el);
    tippy(el, {content: 'Copied!', theme: 'dark', trigger: 'click'});
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initIcons(wrapper = 'body') {
    document.querySelectorAll(`${wrapper} .js-copy-icon`).forEach(el => {
      new CopyIcon().init(el);
    });
  }
  initIcons();

  document.addEventListener('html-updated', e => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initIcons(wrapper);
  });

  document.addEventListener('sm-modal:opened', () => {
    initIcons('.js-sm-modal');
  });
});