export default function() {
  const original_handler = this.onKeyDown;
	this.hook('instead', 'onKeyDown', function(e) {
    if (e.which == 13) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else {
      return original_handler.apply(this, arguments);
    }
	});
}