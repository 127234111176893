class InfoMessage {
  init(element) {
    this.element = element;
    this.bindEvents();
  }

  bindEvents() {
    const content = this.element.querySelector('.js-message-content').innerHTML;
    this.element.addEventListener('click', function() {
      document.dispatchEvent(new CustomEvent('sm-modal:open', { detail: { content: content } }));
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const initMessages = (wrapper = 'body') => {
    document.querySelectorAll(`${wrapper} .js-info-message-icon`).forEach(el => {
      new InfoMessage().init(el);
    });
  };

  initMessages();

  // Initialize info messages rendered dynamically
  document.addEventListener('html-updated', e => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initMessages(wrapper);
  });

  // Initialize info messages rendered within a modal component
  document.addEventListener('sm-modal:opened', () => {
    initMessages('.js-sm-modal');
  });
});
