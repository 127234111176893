class RequiredListingUpdatesUtils {
  // TIP: use "js-not-submitted-input" class for inputs
  // which are not meant to be submitted (search, etc)
  static anyNonHiddenInputsFilled(fieldsWrapper) {
    const inputs = fieldsWrapper.querySelectorAll('input:not([type=button]):not([type=hidden]):not(.js-not-submitted-input):not([disabled]), select:not([disabled]), textarea:not([disabled])');
    const filledInputs = [...inputs].filter(function(input) {
      return !!input.value;
    });
    return !!filledInputs.length;
  }
}

export default RequiredListingUpdatesUtils;