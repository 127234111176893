class SmToggle {
  #item;

  init(el) {
    const color = el.dataset.color;
    const secondaryColor = el.dataset.secondaryColor;
    const jackColor = el.dataset.jackColor;
    const jackSecondaryColor = el.dataset.jackSecondaryColor;
    const className = el.dataset.className;
    const disabled = el.dataset.disabled !== undefined;
    const disabledOpacity = el.dataset.disabledOpacity;
    const speed = el.dataset.speed;
    if (!el.dataset.switchery){
      this.#item = new Switchery(el, {size: 'small', color, secondaryColor, jackColor, jackSecondaryColor, className, disabled, disabledOpacity, speed});
      el.dispatchEvent(new CustomEvent('sm-toggle:inited', {detail: {toggle: this.#item}}));
      this.bindEvents();
    }
  }

  bindEvents() {
    this.#item.element.addEventListener('sm-toggle:set-state', (e) => {
      const val = e.detail.value;
      if((val && !this.#item.isChecked()) ||
        (!val && this.#item.isChecked())) {
        this.#item.setPosition(true);
        this.#item.handleOnchange(val);
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initToggles(wrapper = 'body') {
    document.querySelectorAll(`${wrapper} .js-sm-toggle`).forEach(el => {
      new SmToggle().init(el);
    });
  }
  initToggles();

  document.addEventListener('html-updated', e => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initToggles(wrapper);
  });

  document.addEventListener('sm-modal:opened', () => {
    initToggles('.js-sm-modal');
  });

  document.addEventListener('sm-toggle:init', e => {
    const el = document.querySelector(e.detail.el);
    if (el) {
      new SmToggle().init(el);
    }
  });
});