import '@melloware/coloris/dist/coloris.css';
import Coloris from '@melloware/coloris';
import './styles.scss';

class SMColorpicker {
  init(el) {
    if (!el.className.includes('coloris-')) {
      const uid = Math.random().toString(16).slice(2);
      el.classList.add('coloris-' + uid);
      
      Coloris({
        el: `.coloris-${uid}`,
        alpha: true
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initFields(container = 'body') {
    const pickers = document.querySelectorAll(`${container} .js-sm-colorpicker`);
    pickers.forEach(el => {
      new SMColorpicker().init(el);
    });
  }

  Coloris.init();
  
  initFields();

  document.addEventListener('html-updated', (e) => {
    initFields(e.detail ? e.detail.container : undefined);
  });
  document.addEventListener('sm-modal:opened', () => {
    initFields('.js-sm-modal');
  });
});