import Quill from 'quill';

class Insert {
  #insertableHtmlItems = {
    'Insert Line Break': 'addBreak' ,
    'Insert Paragraph Break': 'addP' ,
    'Insert Horizontal Line': 'addDivider' 
  };
  #moduleSelector = '.ql-insert';
  #labelSelector = '.ql-insert .ql-picker-label';
  #itemSelector = '.ql-insert .ql-picker-item';
  
  constructor(quill) {    
    this.container = quill.container;
    this.toolbar = this.container.previousElementSibling;
    if (this.toolbar.querySelector(this.#moduleSelector)) {
      const placeholderPickerItems = [...this.toolbar.querySelectorAll(this.#itemSelector)];
      placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
      this.toolbar.querySelector(this.#labelSelector).innerHTML
        = 'Insert ' + this.toolbar.querySelector(this.#labelSelector).innerHTML;
  
      this.bindEvents(quill);
    } 
  }

  bindEvents(quill) {
    this.toolbar.querySelectorAll(this.#itemSelector).forEach((item) => {
      item.addEventListener('click', (e) => {
        const format = this.#insertableHtmlItems[e.target.dataset.value];
        const handler = this[format];
        if (typeof handler === 'function') {
          handler(quill);
        }
      });
    });
  }

  addDivider(quill) {
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }

  addBreak(quill) {
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, 'sm-break', true, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }

  addP(quill) {
    const range = quill.getSelection(true);
    quill.insertText(range.index, '\n', true, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
}

Quill.register('modules/Insert', Insert);