import FetchUtils from 'src/utils/fetch_utils';

class AddressPicker {
  #el;
  #addresses;

  setAvailableItemsInfo() {
    this.#el.dataset.available = this.#el.querySelectorAll('#available_address_select option').length;
    this.#el.dataset.visible = [...this.#el.querySelectorAll('#available_address_select option')]
      .filter(el => el.style.display !== 'none').length;
  }

  init (el) {
    this.#el = el;
    this.setAvailableItemsInfo();
    this.bindEvents();

    const remove_address = (e) => {
      if( confirm('This action will remove the address from this property. No data will be lost.') ) {
        const removed_address = e.target.closest('.address_item');
        removed_address.style.display = 'none';
        removed_address.querySelector('input').setAttribute('disabled', 'disabled');

        const availableAddressSelect = this.#el.querySelector('#available_address_select');
        const option = document.createElement('option');
        availableAddressSelect.options[0].insertAdjacentElement('afterend', option);
        option.textContent = removed_address.getAttribute('data-address');
        option.setAttribute('value', removed_address.getAttribute('data-id'));
        option.setAttribute('data-lat', removed_address.getAttribute('data-lat'));
        option.setAttribute('data-long', removed_address.getAttribute('data-long'));
          
        this.setAvailableItemsInfo();
        this.#el.dispatchEvent(new CustomEvent('address.remove'));
      }

      return false;
    };

    this.#el.querySelectorAll('.remove_address').forEach(icon => {
      icon.addEventListener('click', remove_address.bind(this));
    });

    this.#el.querySelector('#hide_available_address').addEventListener('click', () => {
      this.#el.querySelector('#available_address_select').setAttribute('disabled', 'disabled');
      this.#el.querySelector('#available_address').style.display = 'none';

      this.#el.dispatchEvent(new CustomEvent('address.remove'));
    });

    this.#el.querySelector('#available_address_select').addEventListener('change', () => {
      const availableAddressSelect = this.#el.querySelector('#available_address_select');
      const address_id = availableAddressSelect.value;
      const selected_option = availableAddressSelect.options[availableAddressSelect.selectedIndex];
      const address = selected_option.textContent;
      let lat = selected_option.dataset.lat;
      let long = selected_option.dataset.long;

      // coordinates are blank or invalid
      if(isNaN(parseFloat(lat) * parseFloat(long))) {
        fetch(`/geocode?address_id=${address_id}`).then(FetchUtils.checkResponseStatus)
          .then((resp) => {
            return resp.json();
          }).then(coordinates => {
            if(coordinates.hasOwnProperty('longitude')) {
              lat = coordinates.latitude;
              long = coordinates.longitude;
            }
          }).catch((err) => {
            FetchUtils.handleResponseError(err);
          });
      }

      let newAddressItem = document.createElement('div');
      availableAddressSelect.insertAdjacentElement('beforebegin', newAddressItem);
      newAddressItem.innerHTML = document.querySelector('.js-address-item-template').innerHTML;
      newAddressItem.classList.add('address_item');
      newAddressItem.setAttribute('data-address', address);
      newAddressItem.setAttribute('data-id', address_id);
      newAddressItem.setAttribute('data-lat', lat);
      newAddressItem.setAttribute('data-long', long);
      newAddressItem.querySelector('input').value = address_id;
      newAddressItem.querySelector('.address').textContent = address;
      newAddressItem.querySelector('.remove_address').addEventListener('click', remove_address);
      

      availableAddressSelect.options[availableAddressSelect.selectedIndex].remove();
      availableAddressSelect.options[0].setAttribute('selected', 'selected');

      if(this.#el.querySelector('#available_address_select option').length === 1) {
        this.#el.querySelector('#available_address').style.display = 'none';
      }
      this.#el.querySelector('#available_address_select').tomselect.setValue(this.#el.querySelector('#available_address_select').value, true);
      this.setAvailableItemsInfo();
      this.#el.dispatchEvent(new CustomEvent('address.add'));
    });
    this.#addresses = this.#el.querySelector('#available_address_select').innerHTML;
    return this;
  }

  reset() {
    this.#el.querySelector('#available_address_select').innerHTML = this.#addresses;
    this.#el.querySelector('.address_item:not([data-persisted="true"])').remove();
  }

  showPicker() {
    if(this.#el.querySelector('#available_address_select').getAttribute('disabled') === 'disabled') {
      this.#el.querySelector('#available_address_select').removeAttribute('disabled');
      this.#el.querySelector('#available_address').style.display = 'block';
    }
  }

  bindEvents() {
    this.#el.addEventListener('address-picker:show', this.showPicker.bind(this));
    this.#el.addEventListener('address-picker:reset', this.reset.bind(this));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initAddressPicker(wrapper = 'body') {
    document.querySelectorAll(`${wrapper} .js-address-picker`).forEach(el => {
      new AddressPicker().init(el);
    });
  }
  initAddressPicker();

  document.addEventListener('html-updated', e => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initAddressPicker(wrapper);
  });

  document.addEventListener('sm-modal:opened', () => {
    initAddressPicker('.js-sm-modal');
  });
});