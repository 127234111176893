import PartialFormSwitches from './partial_form_switches';

class PartialForm {
  #form;
  constructor() {
    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
  }
  
  bindEvents() {
    this.#form.addEventListener('partial-form:enable', this.enable);
    this.#form.addEventListener('partial-form:disable', this.disable);
    const editBtn = this.#form.querySelector('.partialform-button-edit');
    const cancelBtn = this.#form.querySelector('.partialform-button-cancel');
    const saveBtn = this.#form.querySelector('.partialform-button-save');
    if (editBtn) {
      editBtn.addEventListener('click', this.enable);
    }
    if (cancelBtn) {
      cancelBtn.addEventListener('click', this.disable);
    }
    if (saveBtn) {
      saveBtn.addEventListener('click', (e) => {
        e.preventDefault();
        var remote = this.#form.attributes['data-remote'];
        if (remote && remote.value == 'true') {
          Rails.fire(this.#form, 'submit');
        } else {
          this.#form.submit();
        }
        return false;
      });
    }
  }

  init(el, hasErrors) {
    // Do not try to initialize if already initialized
    if (el.classList.contains('partialform-inited')) {
      return;
    }
    el.classList.add('partialform-inited');

    this.#form = el;
    this.bindEvents();
    new PartialFormSwitches().init(this.#form);
    if (hasErrors) {
      this.enable();
    }
  }

  enable() {
    this.#form.classList.add('enabled');
    var editContent = this.#form.querySelectorAll('.partialform-edit');
    editContent.forEach(el => {
      el.style.display = el.dataset.inline ? 'inline' : 'inline-block';
    });
    this.#form.querySelectorAll('.partialform-show').forEach(el => {
      el.style.display = 'none';
    });
    const errWrap = this.#form.querySelector('.error_explanation');
    if (errWrap) {
      errWrap.style.display = 'block';
    }

    $(this.#form).trigger('enable');

    this.#form.dispatchEvent(new CustomEvent('enabled'));
    return false;
  }

  disable() {
    this.#form.classList.remove('enabled');
    this.#form.querySelectorAll('.partialform-edit').forEach(el => {
      el.style.display = 'none';
    });
    var showContent = this.#form.querySelectorAll('.partialform-show');
    showContent.forEach(el => {
      el.style.display = el.dataset.inline ? 'inline' : 'inline-block';
    });
    const errWrap = this.#form.querySelector('.error_explanation');
    if (errWrap) {
      errWrap.style.display = 'none';
    }
    
    this.#form.reset();
    $(this.#form).trigger('disable');

    this.#form.dispatchEvent(new CustomEvent('cancelled'));

    return false;
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initForms(container = 'body', hasErrors = false) {
    document.querySelectorAll(`${container} .js-sm-partial-form`).forEach(el => {
      new PartialForm().init(el, hasErrors);
    });
  }
  
  initForms();

  document.addEventListener('html-updated', (e) => {
    initForms(e.detail ? e.detail.container : undefined, e.detail && e.detail.errors ? JSON.parse(e.detail.errors) : false);
  });
  document.addEventListener('sm-modal:opened', () => {
    initForms('.js-sm-modal');
  });
});
