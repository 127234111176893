import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.css';
import './styles.scss';

import prevent_select_by_enter from './plugins/prevent_select_by_enter/plugin.js';
TomSelect.define('prevent_select_by_enter', prevent_select_by_enter);

import prevent_select_whole_optgroup from './plugins/prevent_select_whole_optgroup/plugin.js';
TomSelect.define('prevent_select_whole_optgroup', prevent_select_whole_optgroup);

import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button.js';
TomSelect.define('remove_button', TomSelect_remove_button);

window.TomSelect = TomSelect;

class RichUiSelect {
  init(field) {
    if (!field.tomselect) {
      let settings = field.dataset;
      let params = {
        closeAfterSelect: true,
        hideSelected: false,
        sortField:[{field:'$order'},{field:'$score'}],
        hidePlaceholder: true,
        maxOptions: null,
        onInitialize: () => {
          document.dispatchEvent(new CustomEvent('sm-rich-ui-select:initialized', {detail: {field}}));
        }
      };

      if (field.dataset.removeButton) {
        params.plugins = ['remove_button'];
      }

      new TomSelect(field, {...settings, ...params});

      field.addEventListener('sm-rich-ui-select:disable', (e) => {
        if (e.target && e.target.tomselect) {
          e.target.tomselect.disable();
        }
      });

      field.addEventListener('sm-rich-ui-select:enable', (e) => {
        if (e.target && e.target.tomselect) {
          e.target.tomselect.enable();
        }
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initRichUiSelect(container = 'body') {
    document.querySelectorAll(`${container} .js-sm-rich-ui-select:not(.ts-wrapper)`).forEach(el => {
      const smSelect = new RichUiSelect();
      smSelect.init(el, el.dataset);
    });
  }
  
  initRichUiSelect();

  document.addEventListener('html-updated', (e) => {
    initRichUiSelect(e.detail ? e.detail.container : undefined);
  });
  document.addEventListener('sm-modal:opened', () => {
    initRichUiSelect('.js-sm-modal');
  });
  document.addEventListener('sm-rich-ui-select:init', (e) => {
    const el = e.detail.el;
    if (el) {
      const smSelect = new RichUiSelect();
      smSelect.init(el, el.dataset);
    }
  });
});
