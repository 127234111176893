const BlockEmbed = Quill.import('blots/block/embed');
let Embed = Quill.import('blots/embed');
import Quill from 'quill';

class BreakBlot extends BlockEmbed {
  static blotName = 'sm-break';
  static tagName = 'br';
}

Quill.register(BreakBlot);

// Using to prevent Quill wrap line break into paragraph
class SmartBreak extends Embed {
  static blotName = 'smart-break';
  static tagName = 'BR';
}

Quill.register(SmartBreak);