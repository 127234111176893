import Quill from 'quill';

class InsertPlaceholder {
  #moduleSelector = '.ql-placeholder';
  #labelSelector = '.ql-placeholder .ql-picker-label';
  #itemSelector = '.ql-placeholder .ql-picker-item';
  insertablePlaceholders = [];

  constructor(quill) {
    this.quill = quill;
    this.container = this.quill.container;
    this.form = this.container.closest('form');
    this.toolbar = this.container.previousElementSibling;

    if (this.toolbar.querySelector(this.#moduleSelector)) {
      this.insertablePlaceholders = JSON.parse(this.container.getAttribute('data-insertable-placeholders') || '[]');
      this.fullWidthPlaceholders = this.fullWidthPlaceholders();
      this.placeholderValueByName = this.placeholderValuesByNames();
      this.placeholderNameByValue = this.placeholderNamesByValues();
  
      const placeholderPickerItems = [...this.toolbar.querySelectorAll(this.#itemSelector)];
      placeholderPickerItems.forEach(item => item.textContent = this.placeholderNameByValue[item.dataset.value]);
      this.toolbar.querySelector(this.#labelSelector).innerHTML
      = 'Insert Placeholder' + this.toolbar.querySelector(this.#labelSelector).innerHTML;

      this.bindEvents();
    }
  }

  isFocusOnPlaceholder(range) {
    if (!range) return false;
    const elem = this.quill.getLeaf(range.index)[0].domNode;
    return elem.parentElement.getAttribute('contenteditable') == 'false';
  }

  keyboardClearHandler() {
    const range = this.quill.getSelection();
    const elem = this.quill.getLeaf(range.index)[0].domNode.parentElement;

    // If key pressed while placeholder is in focus
    if (this.isFocusOnPlaceholder(range)) {
      elem.remove();
    } else {
      return true;
    }
  }

  keyboardEnterHandler() {
    const range = this.quill.getSelection();

    return !this.isFocusOnPlaceholder(range);
  }

  bindEvents() {
    this.quill.on('selection-change', (range) => {
      // Disable placeholder toolbar if editor focus is on placeholder or if form is disabled
      if (this.isFocusOnPlaceholder(range)) {
        this.toolbar.classList.add('disabled');
      } else if (this.form.classList.contains('enabled')) {
        this.toolbar.classList.remove('disabled');
      }
    });

    this.toolbar.querySelectorAll(this.#itemSelector).forEach((item) => {
      item.addEventListener('click', (e) => {
        const value = e.target.dataset.value;
        if (value && !this.isFocusOnPlaceholder(this.quill.getSelection(true))) {
          if (this.fullWidthPlaceholders.includes(value)) {
            this.insertFullWidthPlaceholder(value);
          } else {
            this.insertInlinePlaceholder(value);
          }
        }
      });
    });
  }

  fullWidthPlaceholders() {
    const placeholdersJson = this.container.getAttribute('data-full-width-placeholders');
    return !!placeholdersJson ? JSON.parse(placeholdersJson) : []; // eslint-disable-line
  }

  placeholderValuesByNames() {
    var placeholders = this.insertablePlaceholders;
    var res = {};
    for(var i = 0; i < placeholders.length; i++) {
      res[placeholders[i]['name']] = placeholders[i]['value'];
    }
    return res;
  }
  
  placeholderNamesByValues() {
    var placeholders = this.insertablePlaceholders;
    var res = {};
    for(var i = 0; i < placeholders.length; i++) {
      res[placeholders[i]['value']] = placeholders[i]['name'];
    }
    return res;
  }

  insertFullWidthPlaceholder(placeholderValue) {
    const cursorPosition = this.quill.getSelection(true).index;
    const text = this.placeholderNameByValue[placeholderValue];
    this.quill.insertText(cursorPosition, text + ' ', Quill.sources.USER);
    this.quill.formatText(cursorPosition, text.length, 'full-width-placeholder', true);
    this.quill.setSelection(cursorPosition + text.length + 1, Quill.sources.SILENT);
  }
  
  insertInlinePlaceholder(placeholderValue) {
    const cursorPosition = this.quill.getSelection(true).index;
    const text = this.placeholderNameByValue[placeholderValue];
    this.quill.insertText(cursorPosition, ' ' + text + ' ', Quill.sources.USER);
    this.quill.formatText(cursorPosition + 1, text.length, 'inserted-placeholder', true);
    this.quill.setSelection(cursorPosition + text.length + 2, Quill.sources.SILENT);
  }
}

Quill.register('modules/InsertPlaceholder', InsertPlaceholder);