import PlainShowtimePicker from './plain_timepicker';
import NestedShowtimePicker from './nested_timepicker';

class TimepickerBuilder {
  static init(wrapper) {
    if (wrapper) {
      if (wrapper.classList.contains('simple')) {
        new PlainShowtimePicker().init(wrapper);
      } else {
        new NestedShowtimePicker().init(wrapper);
      }
    }
  }

  static isSuitableTime(container, value) {
    return !!container.querySelector('.showtime[data-display="' + value + '"]') || !container.querySelector('.showtime');
  }
}

window.TimepickerBuilder = TimepickerBuilder;