class PartialFormSwitches { // eslint-disable-line no-unused-vars
  #switches = [];
  #switchesEnabledOnStart = [];
  #form;

  initSwitches() {
    this.#form.querySelectorAll('.switch-list input[type=checkbox]').forEach((elem) => {
      elem.addEventListener('sm-toggle:inited', (e) => {
        var item = e.detail.toggle;
        if (!this.#form.dataset.switchesEnabled) {
          item.disable();
        }
        
        this.#switches.push(item);
        if (item.element.checked) {
          this.#switchesEnabledOnStart.push(item);
        }
      });
    });
  }

  bindFormEvents() {
    this.#form.addEventListener('enabled', () => {
      this.toggleSwitchesAvailability(true);
    });

    this.#form.addEventListener('cancelled', () => {
      this.toggleSwitchesAvailability(false);
      this.revertSwitchesToInitialState();
    });

    this.#form.addEventListener('toggleSwitch', this.toggleSwitch.bind(this));
    this.#form.addEventListener('uncheckAll', this.uncheckAll.bind(this));
  }

  // NOTE: this does not check / uncheck switches,
  // but sets them to editable / not editable (greyed out) state.
  toggleSwitchesAvailability(enable) {
    this.#switches.forEach(function(item) { enable ? item.enable() : item.disable(); });
  }

  revertSwitchesToInitialState() {
    this.#switches.forEach((item) => {
      if (this.#switchesEnabledOnStart.indexOf(item) != -1) {
        item.element.checked = true;
      } else {
        item.element.checked = false;
      }
      item.setPosition(false);
    });
  }

  uncheckAll() {
    this.#switches.forEach(function(item) {
      item.element.checked = false;
      item.setPosition(false);
    });
  }

  toggleSwitch(e) {
    const checkboxIdOrClass = e.detail.checkboxIdOrClass;
    this.#switches.forEach(function(item) {
      if (item.element.id == checkboxIdOrClass || item.element.classList.contains(checkboxIdOrClass)) {
        item.element.checked = !item.element.checked;
        item.setPosition(false);
      }
    });
  }

  init(form) {
    this.#form = form;
    this.initSwitches();
    this.bindFormEvents();
  }
}

export default PartialFormSwitches;