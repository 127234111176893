import './styles.scss';

class DateField {
  init(elem, flatpickr) {
    flatpickr.default(elem, {...{dateFormat: 'M j, Y', 
      appendTo: document.querySelector('.js-sm-modal') ? document.querySelector('.js-sm-modal'): document.querySelector('body'),
      onReady: (selectedDates, dateStr, instance) => {
      elem.dispatchEvent(new CustomEvent('sm-datepicker:inited', {detail: {instance}}));

      if (elem.dataset.mode === 'range') {
        elem.addEventListener('change', () => {
          const dates = elem._flatpickr.selectedDates;
          document.querySelector(elem.dataset.startSelector || '#start_date').value = moment(dates[0]).format('MMM DD, YYYY');
          document.querySelector(elem.dataset.endSelector || '#end_date').value = moment(dates[1]).format('MMM DD, YYYY');
        });
      }
    }}, ...elem.dataset});
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const calendars = document.querySelectorAll('.js-sm-flatpickr');
  function initCalendars(flatpickr, container = 'body') {
    document.querySelectorAll(`${container} .js-sm-flatpickr`).forEach(el => {
      new DateField().init(el, flatpickr);
    });
  }
  if (calendars.length > 0) {
    import('flatpickr')
      .then((flatpickr) => {
        initCalendars(flatpickr);

        document.addEventListener('html-updated', (e) => {
          initCalendars(flatpickr, e.detail ? e.detail.container : undefined);
        });
      });
  }
});
