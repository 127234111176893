import FetchUtils from '../../../utils/fetch_utils';
import './styles.scss';

class Contract {
  #csrfToken;

  signLater(url) {
    fetch(url, {method: 'PUT', headers: {
      'x-csrf-token': this.#csrfToken
    }}).then(FetchUtils.checkResponseStatus).then((resp) => {
      return resp.json();
    }).then((data) => {
      document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {
        modalId: 'contract-modal'
      }}));
      if (!data.success) {
        document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: 
          {content: `<div class="sm-modal-content">${data.message}</div>`}
        }));
      }
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  updateSubmitBtn() {
    const submitBtn = document.querySelector('.js-submit-contract');
    if (document.querySelector('.js-agreed-to-contract').checked) {
      submitBtn.classList.remove('disabled');
    } else {
      submitBtn.classList.add('disabled');
    }
  }

  bindEvents() {
    const signLaterBtn = document.querySelector('.js-sign-later');
    if (signLaterBtn) {
      signLaterBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.signLater(signLaterBtn.href);
        return false;
      });
    }
    
    const form = document.querySelector('.js-contract-form');
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      fetch(form.action, {
        method: form.method,
        'x-csrf-token': this.#csrfToken,
        body: new FormData(form)
      }).then(FetchUtils.checkResponseStatus).then((resp) => {
        return resp.json();
      }).then((data) => {
        if (data.success) {
          document.querySelector('.js-errors').innerHTML = '';
          document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {modalId: 'contract-modal'}}));
          document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: 
            {content: '<div class="sm-modal-content">Thank you for completing the agreement. We\'ve emailed you a copy for your records.</div>'}
          }));
        } else {
          document.querySelector('.js-errors').innerHTML = data.errors;
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });

      return false;
    });

    document.querySelector('.js-agreed-to-contract').addEventListener('change', () => {
      this.updateSubmitBtn();
    });

    document.querySelector('.js-contract-contact-name').addEventListener('change', e => {
      document.querySelector('.js-contract-agreed-by').value = e.target.value;
    });
  }

  init() {
    this.#csrfToken = document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value;
    this.updateSubmitBtn();

    this.bindEvents(); 
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const contractRequired = document.querySelector('.js-contract-display-required');
  if (contractRequired) {
    fetch(contractRequired.dataset.markDisplayedUrl, {method: 'PUT', headers: {
      'x-csrf-token': document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value
    }}).catch((err) => {
      FetchUtils.handleResponseError(err);
    });

    fetch(contractRequired.dataset.url, {
      method: 'GET'
    }).then(FetchUtils.checkResponseStatus).then((resp) => {
      return resp.json();
    }).then((data) => {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
        content: data.html,
        hideCloseButton: true,
        modalId: 'contract-modal',
        onComplete: () => {
          const form = document.querySelector('.js-contract-form');
          if (form) {
            new Contract().init();
          }
        }
      }}));
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }
});
