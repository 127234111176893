import Quill from 'quill';
const BubbleTheme = Quill.import('themes/bubble');

class SmBubbleTheme extends BubbleTheme {
  constructor(quill, options) {
    super(quill, options);

    quill.on('selection-change', range => {
      if (range) {
        quill.theme.tooltip.show();
        quill.theme.tooltip.position(quill.getBounds(range));
      }
    });

    quill.placeholder = quill.container.dataset.placeholder;
  }

  extendToolbar(toolbar) {
    super.extendToolbar(toolbar);
    this.tooltip.textbox.dataset.link = 'Type URL here...';
  }
}

Quill.register('themes/bubble', SmBubbleTheme, true);