import './styles.scss';

class Tabs {
  #wrapper;

  openTab(evt) {
    evt.preventDefault();
    const contents = document.querySelectorAll('.js-sm-tabs-content');
    contents.forEach(el => {
      el.classList.add('sm-tabs-hidden');
    });

    const links = this.#wrapper.querySelectorAll('.js-sm-tabs-link');
    links.forEach(el => {
      el.classList.remove('active');
    });

    document.querySelector(`${evt.currentTarget.hash}`).classList.remove('sm-tabs-hidden');
    evt.currentTarget.classList.add('active');

    document.dispatchEvent(new CustomEvent('sm-modal:update-height'));

    return false;
  }

  bindEvents() {
    const links = this.#wrapper.querySelectorAll('.js-sm-tabs-link');
    links.forEach(l => {
      l.addEventListener('click', (e) => this.openTab(e));
    });

    if (links.length > 0) {
      links.item(0).click();
    } else {
      this.#wrapper.querySelector('.js-sm-tabs-content').classList.remove('sm-tabs-hidden');
      document.dispatchEvent(new CustomEvent('sm-modal:update-height'));
    }
  }

  init(wrapper) {
    this.#wrapper = wrapper;
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const tabs = document.querySelectorAll('.js-sm-tabs-wrapper');
  tabs.forEach(el => {
    new Tabs().init(el);
  });

  document.addEventListener('sm-modal:opened', (e) => {
    if (e.detail && e.detail.modalContainer) {
      const tabs = e.detail.modalContainer.querySelectorAll('.js-sm-tabs-wrapper');
      tabs.forEach(el => {
        new Tabs().init(el);
      });
    }
  });
});

document.addEventListener('SmTabReinitializationEvent', () => {
  const tabs = document.querySelectorAll('.js-sm-tabs-wrapper');
  tabs.forEach(el => {
    new Tabs().init(el);
  });
});
