let Inline = Quill.import('blots/inline');
import Quill from 'quill';

export class PlaceholderBlot extends Inline {
  static blotName = 'inserted-placeholder';
  static className = 'inserted-placeholder';
  static tagName = 'span';

  static create(value) {
    const node = super.create(value);

    node.setAttribute('contenteditable', false);
    return node;
  }

  static formats() {
    return true;
  }
}

Quill.register(PlaceholderBlot);
