export default function() {
  const original_handler = this.onOptionSelect;
  this.hook('instead', 'onOptionSelect', function(e, option) {
    const value = option.dataset.value;
    var isWholeOptgroupValue = value && ('whole' in option.dataset);
    if (isWholeOptgroupValue) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else {
      return original_handler.apply(this, arguments);
    }
  });
}