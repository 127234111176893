// Select field which is changing its width depending on selected value width  

class AdaptiveSelect {
  #select;

  resize() {
    const helperElement =  document.createElement('span');
    helperElement.innerHTML = this.#select.querySelector('option:checked').innerText;
    document.querySelector('#content').appendChild(helperElement);
    const PADDINGS = 30;
    this.#select.style.width = `${helperElement.offsetWidth + PADDINGS}px`;
    this.#select.style.maxWidth = '100%';
    helperElement.remove();
  }

  init(select) {
    this.#select = select;
    select.addEventListener('change', () => this.resize() );
    window.onresize = () => { this.resize(); };
    this.resize();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initSelects(container = 'body') {
    document.querySelectorAll(`${container} .js-adaptive-select`).forEach(el => {
      new AdaptiveSelect().init(el);
    });
  }

  initSelects();

  document.addEventListener('adaptive-select:init', (e) => {
    initSelects(e.detail ? e.detail.container : null);
  });

  document.addEventListener('html-updated', e => {
    const wrapper = e.detail ? e.detail.container : undefined;
    initSelects(wrapper);
  });
});